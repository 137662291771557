import React, { useState, useContext,useEffect } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
// import TextFields from './TextFields';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import { AppContext } from "../../context";
import { NumberInput } from "../NumberInput";
import { APIDataType } from "../../types/ApitParameter";
import { useStateValue } from "../../Store/Store";
import {fetchData} from "../../API/fetchAPI";
import { myConstClass } from "../../constants";
import { log } from "console";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexWrap: "wrap",
            "& > *": {
                margin: theme.spacing(1),
                width: theme.spacing(60),
                height: theme.spacing(16),
                border: "2px solid #F7F9FA",
                borderRadius: " 16px",
                padding: "16px",
                paddingBottom: "0px",
            },
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: "center",
            color: theme.palette.text.secondary,
        },
        subHeading: {
            fontWeight: 700,
            fontSize:'1rem',
        },
        field: {
            width: "140px",
            height: "38px",
            borderRadius: "8px",
            flexDirection: "row",
            fontSize:'14px',
        },
        label: {
            fontFamily: "Roboto",
            fontWeight: 700,
            fontSize: "12px",
            lineHeight: "16px",
        },
        unit: {
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "16px",
            color: "#707374",
        },
        Steam: {
            width: theme.spacing(60),
            height: theme.spacing(14),
        },
        Electricity: {
            width: theme.spacing(140),
            height: theme.spacing(28),
        },
        Fuel: {
            width: theme.spacing(140),
            height: theme.spacing(14),
        },
        CO2: {
            width: theme.spacing(30),
            height: theme.spacing(14),
        },
        fuelMain: {
            paddingLeft:'12px',
        },
    })
);

export default function CostParameter() {
    const classes = useStyles();
// console.log(DataStore());
    // const data = useContext(AppContext);

    // const global = useFetch(myConstClass.GLOBAL_API);
    // console.log(global);
    
        
        const [ stateValue, dispatch ] = useStateValue();
        // useEffect(() => {
        //    console.log("statevalueparamaeter",stateValue);
        // }, [stateValue]);
       

            // var fields = stateValue.reduce(
            // (obj:any, item:any) => Object.assign(obj, { [item.parameter]: item }), {});
            // console.log(stateValue.data["co2_cost"]);


    return (
        <>
            <div className={classes.root}>
                <Paper elevation={0} className={classes.Steam}>
                    <Typography variant="subtitle2" gutterBottom className={classes.subHeading}>
                        Steam cost
                    </Typography>
                    <form noValidate autoComplete="off">
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <label className={classes.label}>
                                    HPS
                                    <Box className={classes.unit} component="span" m={1}>
                                        (€/ton)
                                    </Box>
                                </label>
                                <NumberInput {...Object.assign( {},stateValue.Global["import_steam_hps_cost"],{"Tabtype":"Global"})} />
                            </Grid>
                            <Grid item xs={4}>
                                <label className={classes.label}>
                                    MPS
                                    <Box className={classes.unit} component="span" m={1}>
                                        (€/ton)
                                    </Box>
                                </label>
                                <NumberInput {...Object.assign( {},stateValue.Global["import_steam_mps_cost"],{"Tabtype":"Global"})}  />
                            </Grid>
                            <Grid item xs={4}>
                                <label className={classes.label}>
                                    LPS
                                    <Box className={classes.unit} component="span" m={1}>
                                        (€/ton)
                                    </Box>
                                </label>
                                <NumberInput {...Object.assign( {},stateValue.Global["import_steam_lps_cost"],{"Tabtype":"Global"})}  />
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
                <Paper elevation={0} className={classes.Electricity}>
                    <Typography variant="subtitle2" gutterBottom className={classes.subHeading}>
                        Electricity cost
                    </Typography>
                    <form noValidate autoComplete="off">
                        <Grid container spacing={3}>
                            <Grid item xs={3}>
                                <label className={classes.label}>
                                    Network
                                    <Box className={classes.unit} component="span" m={1}>
                                        (€/MWh)
                                    </Box>
                                </label>
                                <NumberInput {...Object.assign( {},stateValue.Global["import_elec_network_cost"],{"Tabtype":"Global"})} />
                            </Grid>
                            <Grid item xs={3}>
                                <label className={classes.label}>
                                    Pendelband
                                    <Box className={classes.unit} component="span" m={1}>
                                        (€/MWh)
                                    </Box>
                                </label>
                                <NumberInput {...Object.assign( {},stateValue.Global["import_elec_pb_cost"],{"Tabtype":"Global"})} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={3}>
                                <label className={classes.label}>
                                    Elec Fees Network
                                    <Box className={classes.unit} component="span" m={1}>
                                        (€/MWh)
                                    </Box>
                                </label>
                                <NumberInput {...Object.assign( {},stateValue.Global["import_elec_fees_network"],{"Tabtype":"Global"})} />
                            </Grid>
                            <Grid item xs={3}>
                                <label className={classes.label}>
                                Elec Fees Pendelband
                                    <Box className={classes.unit} component="span" m={1}>
                                        (€/MWh)
                                    </Box>
                                </label>
                                <NumberInput {...Object.assign( {},stateValue.Global["import_elec_fees_pendelband"],{"Tabtype":"Global"})} />
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
                <Paper elevation={0} className={classes.Fuel}>
                    <Typography variant="subtitle2" gutterBottom className={classes.subHeading}>
                        Fuel cost
                    </Typography>
                    <form noValidate autoComplete="off">
                        <Grid container spacing={3} >
                            <Grid item xs={2}>
                                <label className={classes.label}>
                                    Fuel oil
                                    <Box className={classes.unit} component="span" m={1}>
                                        (€/ton)
                                    </Box>
                                </label>
                                <NumberInput {...Object.assign( {},stateValue.Global["fo_cost"],{"Tabtype":"Global"})} />
                            </Grid>
                            <Grid item xs={2}>
                                <label className={classes.label}>
                                    Fuel gas
                                    <Box className={classes.unit} component="span" m={1}>
                                    (€/MWh)
                                    </Box>
                                </label>
                                <NumberInput {...Object.assign( {},stateValue.Global["fg_cost"],{"Tabtype":"Global"})}/>
                            </Grid>
                            <Grid item xs={2}>
                                <label className={classes.label}>
                                    Natural gas
                                    <Box className={classes.unit} component="span" m={1}>
                                    (€/MWh)
                                    </Box>
                                </label>
                                <NumberInput {...Object.assign( {},stateValue.Global["ng_cost"],{"Tabtype":"Global"})} />
                            </Grid>
                            <Grid item xs={2}>
                                <label className={classes.label}>
                                HEL
                                    <Box className={classes.unit} component="span" m={1}>
                                        (€/ton)
                                    </Box>
                                </label>
                                <NumberInput {...Object.assign( {},stateValue.Global["hel_cost"],{"Tabtype":"Global"})} />
                            </Grid>
                            <Grid item xs={3}>
                                <label className={classes.label}>
                                Methanol for SFSG
                                    <Box className={classes.unit} component="span" m={1}>
                                        (€/ton)
                                    </Box>
                                </label>
                                <NumberInput {...Object.assign( {},stateValue.Global["sfsg_cost"],{"Tabtype":"Global"})} />
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
                <Paper elevation={0} className={classes.Fuel}>
                    <Typography variant="subtitle2" gutterBottom className={classes.subHeading}>
                        CO2 cost
                    </Typography>
                    <form noValidate autoComplete="off">
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <label className={classes.label}>
                                    <Box className={classes.unit} component="span" m={1}>
                                    (€/ton)
                                    </Box>
                                </label>
                                <NumberInput {...Object.assign( {},stateValue.Global["co2_cost"],{"Tabtype":"Global"})} />
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
                <Paper elevation={0} className={classes.Fuel}>
                    <Typography variant="subtitle2" gutterBottom className={classes.subHeading}>
                        GT2 cost
                    </Typography>
                    <form noValidate autoComplete="off">
                        <Grid container spacing={3} >
                            <Grid item xs={2}>
                                <label className={classes.label}>
                                  Elec Fees
                                    <Box className={classes.unit} component="span" m={1}>
                                        (€/MWh)
                                    </Box>
                                </label>
                                <NumberInput {...Object.assign( {},stateValue.GT2["elec_fees"],{"Tabtype":"GT2"})} />
                            </Grid>
                            <Grid item xs={2}>
                                <label className={classes.label}>
                                    Standby
                                    <Box className={classes.unit} component="span" m={1}>
                                    (€/h)
                                    </Box>
                                </label>
                                <NumberInput {...Object.assign( {},stateValue.GT2["standby_fees"],{"Tabtype":"GT2"})}/>
                            </Grid>
                            <Grid item xs={2}>
                                <label className={classes.label}>
                                    Boilerfeedwater
                                    <Box className={classes.unit} component="span" m={1}>
                                    (€/M<sup>3</sup>)
                                    </Box>
                                </label>
                                <NumberInput {...Object.assign( {},stateValue.GT2["boilerfeedwater_price"],{"Tabtype":"GT2"})} />
                            </Grid>
                            <Grid item xs={2}>
                                <label className={classes.label}>
                               NG Fee
                                    <Box className={classes.unit} component="span" m={1}>
                                        (€/MWh)
                                    </Box>
                                </label>
                                <NumberInput {...Object.assign( {},stateValue.GT2["ng_fee"],{"Tabtype":"GT2"})} />
                            </Grid>
                            <Grid item xs={2}>
                                <label className={classes.label}>
                               Netback HPS
                                    <Box className={classes.unit} component="span" m={1}>
                                        (€/ton)
                                    </Box>
                                </label>
                                <NumberInput {...Object.assign( {},stateValue.GT2["netback_LPS"],{"Tabtype":"GT2"})} />
                            </Grid>
                            <Grid item xs={2}>
                                <label className={classes.label}>
                               Netback 
                                    <Box className={classes.unit} component="span" m={1}>
                                        (€/ton)
                                    </Box>
                                </label>
                                <NumberInput {...Object.assign( {},stateValue.GT2["netback_HPS"],{"Tabtype":"GT2"})} />
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </div>
        </>
    );
}
