import * as React from "react";
import logo from "./logo.svg";
import "./App.css";
// import UserList from './API/fetchAPI';
import { fetchData } from "./API/fetchAPI";
import { useEffect, useState, useReducer } from "react";
import { AppContext } from "./context";
import Structure from "./views/Structure/stucture";
import axios, { AxiosResponse } from "axios";
import { myConstClass } from "./constants";
import { StateProvider, useStateValue } from "./Store/Store";
import reducer, { initialState } from "./Store/reducer";
import { DecimalTwoDigit } from "./tools/timeutil";
import { Timestamps } from "./tools/timeutil";
import DownloadButton from "./components/Tabcomponents/results/DownloadButton";
import ModelShow from "./components/ModelShow";
interface User {
    id: number;
    email: string;
    first_name: string;
    optim_id: number;
    optim_output: null;
    value: number;
    timestamp: string;
    param_id: number;
    units_id: number;
    parameter: string;
    measured_in: String;
}

interface APIType {
    global: object;
    RKB: object;
    gt2: object;
}

export interface Sensor {
    data: object;
}

export type Action = { type: "set" };

function App() {
    // const [state, dispatch] = useReducer(reducer, initialState);
    const [state, dispatch] = useStateValue();
    const [resposeGlobal, setResposeGlobal] = useState<any | undefined>([]);
    const [resposeGT2, setResposeGT2] = useState<any | undefined>([]);
    const [resposeRKB, setResposeRKB] = useState<any | undefined>([]);
    const [optimizerData, setOPtimizerData] = useState<any | undefined>([]);
    const [resposeUtilityDemand, setResposeUtilityDemand] = useState<any | undefined>([]);
    const [resposeFutureAPI, setResposeFutureAPI] = useState<any | undefined>([]);
    const [Actual_API, setActual_API] = useState<any | undefined>([]);
    const [ErrorModelActive, setErrorModelActive] = useState<any | undefined>(state.modelActive);

    const Modelcontains = {
        "modelStatus": ErrorModelActive,
        "title":"PI Data",
        "Description":"PI data API is not working due to technical problem.",
    }

    // console.log("global",resposeGlobal);
    // console.log("gt2",resposeGT2);
    // console.log("rkb",resposeRKB);

    // console.log("state",state);
    // to check API data using custom API

    //     const rkb = fetchData(myConstClass.GT2_API).then((response) => {return response.data});
    // console.log("API",rkb);
    // const gt2 = useFetch(myConstClass.RKB_API);
    // const globalFields = global[0].reduce((obj: any, item: any) => Object.assign(obj, { [item.parameter]: item }), {});
    // const RKBFields = rkb[0].reduce((obj: any, item: any) => Object.assign(obj, { [item.parameter]: item }), {});
    // const GT2Fields = gt2[0].reduce((obj: any, item: any) => Object.assign(obj, { [item.parameter]: item }), {});
    // const data: APIType = {...globalFields,...RKBFields,...GT2Fields}

    const storedata = {
        Global: resposeGlobal,
        GT2: resposeGT2,
        RKB: resposeRKB,
    };

    const OptimizerId =(optimID:any, scenarioDefault:any) => {
        if(optimID){
            let optid = optimID[scenarioDefault];
            return optid;
        } else{
            return "";
        }
    }
   
    useEffect(() => {
        dispatch({
            type: "set",
            Future_API: resposeFutureAPI,
        });
    }, [resposeFutureAPI]);
    useEffect(() => {
        dispatch({
            type: "set",
            Actual_API: Actual_API,
        });
    }, [Actual_API]);
    useEffect(() => {
        dispatch({
            type: "set",
            Global: resposeGlobal,
        });
    }, [resposeGlobal]);
    useEffect(() => {
        dispatch({
            type: "set",
            GT2: resposeGT2,
        });
    }, [resposeGT2]);
    useEffect(() => {
        dispatch({
            type: "set",
            RKB: resposeRKB,
        });
    }, [resposeRKB]);

    useEffect(() => {
        if(Object.keys(resposeFutureAPI).length !== 0 && Object.keys(Actual_API).length !== 0 ){
        dispatch({
            type: "set",
            loadUtility: true,
        });
    }
}, [Actual_API]);

    useEffect(() => {
        if (Array.isArray(state.utilityDemandAPI) && state.utilityDemandAPI.length !== 0) {
            let anc = { ...state };
            anc.Global["steam_lps_demand"].value = DecimalTwoDigit(state.utilityDemandAPI[1].aggregate.LPS);
            anc.Global["steam_mps_demand"].value = DecimalTwoDigit(state.utilityDemandAPI[1].aggregate.MPS);
            anc.Global["steam_hps_demand"].value = DecimalTwoDigit(state.utilityDemandAPI[1].aggregate.HPS);
            anc.Global["electricity_demand"].value = DecimalTwoDigit(state.utilityDemandAPI[1].aggregate.ELEC);
            dispatch({
                type: "set",
                ...state,
            });
        }
    }, [state.utilityDemandAPI]);

    useEffect(() => {
        fetchData<any>(myConstClass.Actual_API)
            .then((response: any) => {
                let test = {};
                const actualAPIFields: any = response.data.reduce((previousObject: any, currentObject: any) => {
                    if (
                        typeof currentObject.is_throughput != "undefined" &&
                        currentObject.is_throughput != null &&
                        currentObject.is_throughput == 1 &&
                        currentObject.unit !== "431"
                    ) {
                        if (currentObject.unit === "631_451" && currentObject.tag === "I_631B1001:F1002.") {
                            currentObject.unit = "631_451_1";
                            test = { ...test, [currentObject.unit]: currentObject };
                        } else if (currentObject.unit === "651" && currentObject.tag === "I_651B1001:F1015.") {
                            currentObject.unit = "651_1";
                            test = { ...test, [currentObject.unit]: currentObject };
                        } else {
                            test = { ...test, [currentObject.unit]: currentObject };
                        }
                        return test;
                    }
                }, {});
                setActual_API(actualAPIFields);
                // console.log("fields",actualAPIFields);
            })
            .catch((error: any) => {
                // console.log(error);
                let anc = { ...state };
                    anc.modelActive = true;
                    dispatch({
                        type: "set",
                        ...anc,
                    });
            });
        fetchData<any>(myConstClass.Future_API)
            .then((response: any) => {
                // console.log(response);
                let test = {};
                const flowerAPIFields: any = response.data.reduce(
                    (obj: any, item: any) => {
                        if (
                            typeof item.is_throughput != "undefined" &&
                            item.is_throughput != null &&
                            item.is_throughput == 1 &&
                            item.unit !== "431"
                        ) {
                            if (item.unit === "631_451" && item.tag === "I_631B1001:F1002.") {
                                item.unit = "631_451_1";
                              test = { ...test, [item.unit]: item };
                            } else if (item.unit === "651" && item.tag === "I_651B1001:F1015.") {
                                item.unit = "651_1";
                              test = { ...test, [item.unit]: item };
                            } else {
                              test = { ...test, [item.unit]: item };
                            }
                            return test;
                        }
                        Object.assign(obj, { [item.unit]: item });
                        
                    },
                    {}
                );
                // console.log("apiflower",flowerAPIFields);
                setResposeFutureAPI(flowerAPIFields);
            })
            .catch((error: any) => {
                // console.log(error);
            });
        fetchData<any>(`${myConstClass.GLOBAL_API}${ OptimizerId(state.optimID,state.scenario.default) ? OptimizerId(state.optimID,state.scenario.default) : '1'}`)
            .then((response: any) => {
                // console.log(response);
                // let fields = response.data.reduce(
                //     (obj: any, item: any) => Object.assign(obj, { [item.parameter]: item }),
                //     {}
                // );
                let test = {};
                const fields: any = response.data.reduce((previousObject: any, currentObject: any) => {
                    if (typeof currentObject.parameter != "undefined" && currentObject.parameter != null) {
                        if (
                            currentObject.parameter !== "fo_co2_emission" &&
                            currentObject.parameter !== "hel_co2_emission" &&
                            currentObject.parameter !== "fg_co2_emission" &&
                            currentObject.parameter !== "ng_co2_emission" &&
                            currentObject.parameter !== "sfsg_co2_emission"
                        ) {
                            currentObject = { ...currentObject, ["value"]: DecimalTwoDigit(currentObject.value) };
                        }
                        test = { ...test, [currentObject.parameter]: currentObject };
                        return test;
                    }
                }, {});
                setResposeGlobal(fields);
                // console.log("fields",fields);
            })
            .catch((error: any) => {
                console.log(error);
            });
        fetchData<any>(`${myConstClass.GT2_API}${ OptimizerId(state.optimID,state.scenario.default) ? OptimizerId(state.optimID,state.scenario.default) : '1'}`)
            .then((response: any) => {
                // console.log(response.data);
                // let fields = response.data.reduce(
                //     (obj: any, item: any) => Object.assign(obj, { [item.parameter]: item }),
                //     {}
                // );
                let test = {};
                const fields: any = response.data.reduce((previousObject: any, currentObject: any) => {
                    if (typeof currentObject.parameter != "undefined" && currentObject.parameter != null) {
                        if (
                            currentObject.parameter !== "gt2_operating_mode" &&
                            currentObject.parameter !== "gt2_electricity_a" &&
                            currentObject.parameter !== "gt2_electricity_b" &&
                            currentObject.parameter !== "netback_HPS" &&
                            currentObject.parameter !== "netback_LPS" &&
                            currentObject.parameter !== "ng_fee" &&
                            currentObject.parameter !== "standby_fees" &&
                            currentObject.parameter !== "steam_hps_coeff_a" &&
                            currentObject.parameter !== "steam_hps_coeff_b" &&
                            currentObject.parameter !== "steam_lps_coeff_a" &&
                            currentObject.parameter !== "steam_lps_coeff_b" &&
                            currentObject.parameter !== "steam_mps_coeff_a" &&
                            currentObject.parameter !== "steam_mps_coeff_b" &&
                            currentObject.parameter !== "boilerfeedwater_price" &&
                            currentObject.parameter !== "elec_fees" &&
                            currentObject.parameter !== "elec_consumption"
                        ) {
                            currentObject = { ...currentObject, ["value"]: DecimalTwoDigit(currentObject.value) };
                        }
                        // console.log(currentObject);
                        test = { ...test, [currentObject.parameter]: currentObject };
                        return test;
                    }
                }, {});
                setResposeGT2(fields);
                // console.log("fields",fields);
            })
            .catch((error: any) => {
                console.log(error);
            });
        fetchData<any>(`${myConstClass.RKB_API}${ OptimizerId(state.optimID,state.scenario.default) ? OptimizerId(state.optimID,state.scenario.default) : '1'}`)
            .then((response: any) => {
                // console.log(response.data);
                // let fields = response.data.reduce(
                //     (obj: any, item: any) => Object.assign(obj, { [item.parameter]: item }),
                //     {}
                // );
                let test = {};
                const fields: any = response.data.reduce((previousObject: any, currentObject: any) => {
                    if (typeof currentObject.parameter != "undefined" && currentObject.parameter != null) {
                        if (
                            currentObject.parameter !== "b0_hel_model_coeff_a" &&
                            currentObject.parameter !== "b0_hel_model_coeff_b" &&
                            currentObject.parameter !== "b0_ng_model_coeff_a" &&
                            currentObject.parameter !== "b0_ng_model_coeff_b" &&
                            currentObject.parameter !== "b0_sfsg_model_coeff_a" &&
                            currentObject.parameter !== "b0_sfsg_model_coeff_b" &&
                            currentObject.parameter !== "b1_fo_model_coeff_a" &&
                            currentObject.parameter !== "b1_fo_model_coeff_b" &&
                            currentObject.parameter !== "b1_ng_model_coeff_a" &&
                            currentObject.parameter !== "b1_ng_model_coeff_b" &&
                            currentObject.parameter !== "b1_fg_model_coeff_a" &&
                            currentObject.parameter !== "b1_fg_model_coeff_b" &&
                            currentObject.parameter !== "b2_fo_model_coeff_a" &&
                            currentObject.parameter !== "b2_fo_model_coeff_b" &&
                            currentObject.parameter !== "b2_ng_model_coeff_a" &&
                            currentObject.parameter !== "b2_ng_model_coeff_b" &&
                            currentObject.parameter !== "b2_fg_model_coeff_a" &&
                            currentObject.parameter !== "b2_fg_model_coeff_b" &&
                            currentObject.parameter !== "b3_fo_model_coeff_a" &&
                            currentObject.parameter !== "b3_fo_model_coeff_b" &&
                            currentObject.parameter !== "b3_ng_model_coeff_a" &&
                            currentObject.parameter !== "b3_ng_model_coeff_b" &&
                            currentObject.parameter !== "b3_fg_model_coeff_a" &&
                            currentObject.parameter !== "b3_fg_model_coeff_b" &&
                            currentObject.parameter !== "hpt_steam_stage1_model_coeff_a" &&
                            currentObject.parameter !== "hpt_steam_stage1_model_coeff_b" &&
                            currentObject.parameter !== "mpt_steam_stage1_model_coeff_a" &&
                            currentObject.parameter !== "mpt_steam_stage1_model_coeff_b" &&
                            currentObject.parameter !== "mpt_steam_stage2_model_coeff_a" &&
                            currentObject.parameter !== "mpt_steam_stage2_model_coeff_b" &&
                            currentObject.parameter !== "lpt_steam_stage1_model_coeff_a" &&
                            currentObject.parameter !== "lpt_steam_stage1_model_coeff_b"
                        ) {
                            currentObject = { ...currentObject, ["value"]: DecimalTwoDigit(currentObject.value) };
                        }
                        // console.log(currentObject);
                        test = { ...test, [currentObject.parameter]: currentObject };
                        return test;
                    }
                }, {});
                setResposeRKB(fields);
                // console.log("fields",test);
            })
            .catch((error: any) => {
                console.log(error);
            });
    }, [state.optimID]);

    return (
        <div className="App">  
        {ErrorModelActive && <ModelShow {...Modelcontains}/> }
        <Structure />
        </div>
    );
}

export default App;
